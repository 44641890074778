import React, { Component, Suspense } from 'react'
import './App.css';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import 'react-notifications/lib/notifications.css'
import { NotificationContainer } from "react-notifications"
import LoaderIndicator from './components/UI/LoaderIndicator'


// Containers
const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout/DefaultLayout'))

class App extends Component {

  render() {
    return (
      <>
        <HashRouter>
          <Suspense fallback={<LoaderIndicator />}>
            <Routes>
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </HashRouter>

        <NotificationContainer />
      </>
    )
  }
}

export default App
